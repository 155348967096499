<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-between py-12 sm:px-6 lg:px-8">
    <div></div>
    <div>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {{ title }}
        </h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-4">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="self-center mt-4">
      <LangSwitcher btnClass="bg-gray-50" class="px-2" dropdownClass="bottom-full w-full"  />
    </div>
  </div>
</template>

<script>
import Logo from '@/components/layout/Logo.vue';
import LangSwitcher from '@/components/layout/LangSwitcher.vue'

export default {
  components: {
    Logo,
    LangSwitcher
  },
  props: {
    title: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cls-1 {
  fill: #979797;
}
.cls-2 {
  fill: #4e4e4d;
}
.cls-3 {
  fill: #99be3a;
}
</style>
