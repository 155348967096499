<template>
  <PublicView :title="$t('public.loginTitle')">
    <form class="space-y-6" action="#" method="POST">
      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">
          {{ $t('public.email')}}
        </label>
        <div class="mt-1">
          <input
            v-model="email"
            id="email"
            name="email"
            type="email"
            autocomplete="email"
            required=""
            class="
              appearance-none
              block
              w-full
              px-3
              py-2
              border border-gray-300
              rounded-md
              shadow-sm
              placeholder-gray-400
              focus:outline-none
              focus:ring-ifgreen
              focus:border-ifgreen
              sm:text-sm
            "
          />
        </div>
      </div>

      <div>
        <label
          for="password"
          class="block text-sm font-medium text-gray-700"
        >
        {{ $t('public.password')}}
        </label>
        <div class="mt-1">
          <input
            v-model="password"
            id="password"
            name="password"
            type="password"
            autocomplete="current-password"
            required=""
            class="
              appearance-none
              block
              w-full
              px-3
              py-2
              border border-gray-300
              rounded-md
              shadow-sm
              placeholder-gray-400
              focus:outline-none
              focus:ring-ifgreen
              focus:border-ifgreen
              sm:text-sm
            "
          />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center"></div>

        <div class="text-sm">
          <router-link
            to="/password-recovery"
            class="font-medium text-ifgreen hover:text-ifgreen-dark"
          >
            {{ $t('public.forgotPassword') }}
          </router-link>
        </div>
      </div>

      <div>
        <button
          @click="signIn"
          type="submit"
          class="
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-white
            bg-ifgreen
            hover:bg-ifgreen-dark
            focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen-dark
          "
        >
        {{ $t('public.login') }}
        </button>
      </div>
    </form>
    <div class="mt-6">
      <div class="relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="px-2 bg-white text-gray-500"> {{ $t('public.or') }} </span>
        </div>
      </div>

      <div class="mt-6 flex justify-center">
        <router-link
          to="/register"
          class="font-medium text-ifgreen hover:text-ifgreen-dark"
        >
          {{ $t('public.createAccount') }}
        </router-link>
      </div>
    </div>
  </PublicView>         

  <Modal
    ref="modal"
    :title="$t('public.infos.emailConfirmationReq')"
    :text="$t('public.infos.emailConfirmationReqInfo')"
    :cancel-button-label="$t('cancel')"
  >
    <button
      type="button" class="modal-action-button"
      @click="() => { $router.push({name: 'password-recovery'}) }"
    >
      {{$t("public.infos.changePass")}}
    </button>
    <button
      type="button" class="modal-action-button mt-3"
      @click="setPasswordChanged"
    >
      {{$t("public.infos.dontShow")}}
    </button>
  </Modal>
</template>

<script>
import { api } from "@/assets/js/api-client.js";
import Modal from '@/components/layout/Modal.vue';
import PublicView from '@/components/layout/PublicView.vue'

export default {
  components: {
    Modal,
    PublicView
  },
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {

    async signIn(e) {
      e.preventDefault();
      this.$store.dispatch('setLoading', true)
      console.log("Logging!");
        const { email, password } = this
        api.post('/login', { email, password }).then(() => {
          api.get('/user').then(result => {
            const user = result.data.user
            if (user.roles.includes('ROLE_PRODUCTION')) this.$router.push({ name: "production" })
            else this.$router.push({ name: "home" })
            this.$store.dispatch('setLoading', false)
          })
        }).catch(err => {
          console.log(err.response.data)
          this.$store.dispatch('setLoading', false)
          let errorMessage
          switch (err.response.data.message){
            case "Account not verified.":
              errorMessage = this.$t('public.infos.confirmEmailError');
              break;
            case "Invalid credentials.":
              errorMessage = this.$t('public.infos.invalidCredentials');
              break;
            default:
              errorMessage = this.$t('public.infos.defaultError');
          }
          this.$notify({
            title: `${this.$t('public.error')}`,
            text: errorMessage,
            type: 'error',
          });
        })
    },
    checkPasswordChanged () {
      const passwordChanged = localStorage.getItem('passwordChanged')
      if (process.env.VUE_APP_ORG_ID == 1 && !passwordChanged) {
        this.$refs.modal.show()
      }
    },
    setPasswordChanged () {
      localStorage.setItem('passwordChanged', 'true')
      this.$refs.modal.close()
    }
  },
  mounted () {
    if (this.$route.query.password) {
      localStorage.setItem('passwordChanged', 'true')
      this.$notify({
        title: this.$t('public.success'),
        text: this.$t('public.infos.passwordChanged'),
        type: "success"
      })
    }
    if (this.$route.query.confirmed && this.$route.query.email) {
      this.email = this.$route.query.email;
      this.$notify({
        title: this.$t('public.success'),
        text: this.$t('public.infos.emailConfirmed'),
        type: "success"
      })
    }
    else if (this.$route.query.confirmed && !this.$route.query.email){
      this.$notify({
        title: this.$t('public.error'),
        text: this.$t('public.infos.emailConfirmationError'),
        type: "error",
        duration: 10000
      })
    }
    else if (this.$route.query.nouser){
      this.$notify({
        title: this.$t('public.error'),
        text: this.$t('public.infos.invalidCredentials'),
        type: "error",
        duration: 10000
      })
    }
    this.checkPasswordChanged()
  }
};
</script>
